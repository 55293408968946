import { Helmet } from "react-helmet";
import "./App.css";
import SiteRoutes from "./routes";
import { useEffect, useState } from "react";
import { apiGET } from "./utilities/apiHelpers";

function App() {
	const [metaData, setMetaData] = useState([]);
	let fetchSiteMetadata = async () => {
		try {
			let res = await apiGET(`/v1/site-metadata/public`);
			if (res?.data?.code === 200) {
				let metaList = res?.data?.data;
				if (metaList?.length) {
					metaList.filter(item => {
						if (item.type === "all site metadata") {
							setMetaData(item.statements)
						}
					})
				} else setMetaData([])
			} else {
				setMetaData([])
			}
		} catch (error) {
			console.error("Error fetching site metadata:", error);
		}
	};

	useEffect(() => {
		fetchSiteMetadata();
	}, []);

	return (
		<>
			<div>
				<Helmet>
					<meta
						property="og:description"
						content={
							metaData?.length
								? metaData
								: "We have THE largest product range in the UK with 905+ different flavors, rated excellent on Trustpilot - 4.8/5.0 and 20,000+ happy customers"
						}
					/>
				</Helmet>
			</div>
			<div className="App avenir">
				<SiteRoutes />
			</div>
		</>
	);
}

export default App;
